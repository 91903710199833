$blue: #304FFE;
$blue-light: rgba(48, 79, 254, 0.1);
$font: #000000;
$black: $font;
$white: #FFFFFF;
//$gray-strong: #ECECEC;
//$wapp: #25D366;
//$gray: #F6F6F6;
//$bullet: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTEnIGhlaWdodD0nOScgdmlld0JveD0nMCAwIDExIDknIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTMuNzM2MDkgOC44MjMyNEwwLjE2MTA4NSA0LjkwMDgxQy0wLjA1MzY5NDkgNC42NjUxNiAtMC4wNTM2OTQ5IDQuMjgzMDggMC4xNjEwODUgNC4wNDc0TDAuOTM4ODg0IDMuMTkzOTlDMS4xNTM2NiAyLjk1ODMyIDEuNTAxOTMgMi45NTgzMiAxLjcxNjcxIDMuMTkzOTlMNC4xMjUgNS44MzYzTDkuMjgzMjkgMC4xNzY3MzlDOS40OTgwNyAtMC4wNTg5MTMgOS44NDYzNCAtMC4wNTg5MTMgMTAuMDYxMSAwLjE3NjczOUwxMC44Mzg5IDEuMDMwMTVDMTEuMDUzNyAxLjI2NTggMTEuMDUzNyAxLjY0Nzg5IDEwLjgzODkgMS44ODM1Nkw0LjUxMzkxIDguODIzMjZDNC4yOTkxMSA5LjA1ODkyIDMuOTUwODcgOS4wNTg5MiAzLjczNjA5IDguODIzMjRaJyBmaWxsPScjMzA0RkZFJy8+PC9zdmc+");
//$mobile: 850px;
//$actions-width: 65%;
//$benefits-width: (100%-$actions-width);

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  font-weight: 400;
  background-color: #f5f5f5;
  line-height: 1.7em;

  webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes start {
  0% {
    opacity: 0;
  }
}

.main {
  min-width: 100vw;
  display: flex;
  animation: start 0.7s ease-in;

  //@media (width < $mobile) {
  //  flex-direction: column-reverse;
  //  font-size: 12px;
  //}
}

.logo {
  position: absolute;
  top: 1em;
  left: 2em;
}

.button {
  font-weight: 600;
  font-size: 1.1em;
  color: $white;
  background-color: $blue;
  border: 0.12em solid;
  border-radius: 2em;
  padding: 0.9em 2.5em;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: $blue;
    background-color: $white;
  }
}

.formItem {
  //margin-top: 0.5em;
  margin-right: 1em;
  margin-left: 1em;
  //margin-bottom: 0.5em;
}

.title {
  font-size: 10pt;
}

.MuiTableCell-sizeSmall {
  padding: 1px 24px 1px 16px !important;
}

.section-table {
  margin-top: 2em;
  display: flex;
  justify-content: center;

  .MuiPaper-elevation1 {
    border-color: #c5c0c0 !important;
    border-width: 2px !important;
    border-style: double !important;
    width: 100%;
  }

  h6 {
    padding: 1em !important;
  }
}

.section {
  margin-top: 2em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.root {
  flex-grow: 1;
}

.button-inv {
  @extend .button;

  color: $blue;
  background-color: $white;

  &:hover {
    color: $white;
    background-color: $blue;
  }
}

.buttons-block {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  display: flex;
  justify-content: center;
}

.login-button {
  @extend .button;

  display: flex;
  min-width: 19em;

  img {
    margin: 0 2ch 0 0;
  }
}

.gbutton {
  @extend .login-button;

  color: $black;
  background-color: $white;
  border: 0.12em solid #979797;

  &:hover {
    color: $black;
    background-color: #979797;
  }
}
